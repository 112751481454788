.Login_Wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background: url(../public/img/LOGIN/Main.jpg) no-repeat center center/cover;
  overflow: hidden;
}

/* .Login_Wrap::before {
  position: absolute;
  content: "";
  top: -50px;
  left: 50px;
  background: url(../../public/img/LOGIN/C_01.png) no-repeat center center/cover;
  transform: rotate(20deg);
  width: 700px;
  height: 500px;
  z-index: 0;
}

.Login_Wrap::after {
  position: absolute;
  content: "";
  bottom: -50px;
  right: 50px;
  background: url(../../public/img/LOGIN/C_01.png) no-repeat center center/cover;
  transform: rotate(20deg);
  width: 700px;
  height: 500px;
  z-index: 0;
} */

.Login_Form {
  padding: 100px;
  border-radius: 30px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
  background-color: rgba(13, 17, 35, 0.5);
  z-index: 1;
}

.Login_Form .Inner .Logo {
  margin-bottom: 50px;
}

.Login_Form .Inner .Logo img {
  display: block;
  margin-bottom: 20px;
}

.Login_Form .Inner .Logo p {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 100;
  color: var(--white-color-2);
}

.Login_Form .Inner .Login_Data {
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 30px;
}

.Login_Form .Inner .Login_Data .INP_Container {
  display: flex;
  gap: 30px;

  width: 100%;
  background: rgba(27, 34, 48, 0.8);
  border-radius: 30px;
  padding: 10px 30px;
}

.Login_Form .Inner .Login_Data .INP_Container img {
  display: block;
  height: 30px;
}

.Login_Form .Inner .Login_Data .INP_Container .L_INP {
  width: 100%;
  color: var(--white-color);
  background: transparent;
}

.Login_Form .Inner .Login_BTN {
  display: block;

  width: 100%;
  max-width: 250px;

  margin: 0 auto;
  padding: 15px;

  border-radius: 30px;
  background: var(--blue-color-3);

  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.8px;

  color: var(--white-color);
}

.Login_Form .Inner .Login_BTN:active {
  box-shadow: 3px 3px 5px var(--blue-color-5) inset;
}
