:root {
  --white-color: #e0e0e0;
  --red-color: #ff0018;
  --orange-color: #ff4500;
  --yellow-color: #f9d342;
  --green-color: #00ff44;
  --blue-color: #0051ff;
  --violet-color: #4300ff;
  --purple-color: #9900ff;
  --pink-color: #ff33aa;
  --gray-color: #8c96a5;
  --aqua-color: #1abc9c;
  --black-color: #121216;
  --wrap-color: #0d1023;
  --card-color: #1a2130;

  --red-color-1: #da0014;
  --red-color-2: #b60011;
  --red-color-3: #91000d;
  --red-color-4: #6d000a;
  --red-color-5: #480006;

  --orange-color-1: #da3b00;
  --orange-color-2: #b63100;
  --orange-color-3: #912700;
  --orange-color-4: #6d1d00;
  --orange-color-5: #481300;

  --yellow-color-1: #f7e04d;
  --yellow-color-2: #f1d14a;
  --yellow-color-3: #e3c837;
  --yellow-color-4: #d1b529;
  --yellow-color-5: #a89e22;

  --green-color-1: #00da3a;
  --green-color-2: #00b630;
  --green-color-3: #009126;
  --green-color-4: #006d1d;
  --green-color-5: #004813;

  --blue-color-1: #0045d3;
  --blue-color-2: #0038a1;
  --blue-color-3: #00256e;
  --blue-color-4: #00144a;
  --blue-color-5: #000d27;

  --violet-color-1: #3900da;
  --violet-color-2: #2f00b6;
  --violet-color-3: #260091;
  --violet-color-4: #1c006d;
  --violet-color-5: #130048;

  --purple-color-1: #9c00da;
  --purple-color-2: #8200b6;
  --purple-color-3: #680091;
  --purple-color-4: #53006d;
  --purple-color-5: #220048;

  --white-color-1: #d5d5d5;
  --white-color-2: #b1b1b1;
  --white-color-3: #8d8d8d;
  --white-color-4: #6a6a6a;
  --white-color-5: #484848;

  --dark-gray-color: #31363f;
  --light-blue-color: #4a90e2;
  --dark-blue-color: #003366;
  --light-green-color: #b2ff59;
  --dark-green-color: #004d00;
  --light-purple-color: #d4a5d6;
  --dark-purple-color: #5e2a8a;
}
